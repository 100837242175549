<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Financial Transactions</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Financials' }"
              >Financial</router-link
            >
          </li> 
          <li class="breadcrumb-item active">Transactions</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
            <router-link
              v-can="'add_financial_transactions'"
              :to="{ name: 'Financial-Transactions-Create' }"
              class="btn btn-primary"
              >Add New</router-link
            >
          </div>
          <div class="card-body">
            <div class="search-block row" style="padding-bottom: 15px">
              <div class="col-md-12">
                <!-- form will be here -->
              </div>
            </div>
            <div class="table-responsive">
              <table
                class="
                  table table-bordered table-striped
                  verticle-middle
                  table-responsive-sm
                "
              >
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">ID Number</th>
                    <th scope="col">Heading</th>
                    <th scope="col">Amount</th>
                    <th scope="col">From</th>
                    <th scope="col">To</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody v-if="financial_transactions.length">
                  <tr v-for="transaction in financial_transactions" :key="transaction.id">
                    <td>{{ transaction.date }}</td>
                    <td>{{ transaction.name }}</td>
                    <td>{{ transaction.type }}</td>
                    <td>{{ transaction.id_number }}</td>
                    <td>{{ transaction.heading.name }}</td>
                    <td>{{ transaction.amount }}</td>
                    <td>{{ transaction.account_from.name }}</td>
                    <td>{{ transaction.account_to.name }}</td>
                    <td>
                      <span>
                        <router-link
                          :to="{
                            name: 'Financial-Transactions-Single',
                            params: { id: transaction.id },
                          }"
                          title="View"
                          class="mr-4"
                          ><i class="fa fa-eye color-muted"></i>
                        </router-link>
                        <router-link
                          v-can="'edit_financial_transactions'"
                          :to="{
                            name: 'Financial-Transactions-Edit',
                            params: { id: transaction.id },
                          }"
                          title="Edit"
                          class="mr-4"
                          ><i class="fa fa-pencil color-muted"></i>
                        </router-link>
                        <a
                          v-can="'delete_financial_transactions'"
                          @click.prevent="deleteTransaction(transaction.id)"
                          href="javascript:void()"
                          title="Delete"
                          ><i class="fa fa-close color-danger"></i
                        ></a>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="9">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFinancialTransactions from "@/composables/financial_transactions";
import { onMounted, inject, onBeforeMount, reactive } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    const search_fields = reactive({
      name: "",
      type: "",
      id_number: "",
      email: "",
      phone: "",
    });

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission("access_financial_transactions"))
        router.push({
          name: "401",
        });
    });

    const { financial_transactions, getFinancialTransactions, deleteFinancialTransaction } =
      useFinancialTransactions();

    onMounted(() => {
      getFinancialTransactions();
    });

    const deleteTransaction = async (id) => {
      swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            deleteFinancialTransaction(id).then(() => {
              swal.fire("Deleted!", "The data has been deleted successfully.", "success");
              getFinancialTransactions();
            });
          }
        });
    };

    const submitSearchForm = async () => {
      await getFinancialTransactions(search_fields);
    };

    return {
      financial_transactions,
      deleteTransaction,
      submitSearchForm,
    };
  },
};
</script>